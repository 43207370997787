//! css framework
// @import "../node_modules/bulma/bulma";
@import '../node_modules/tailwindcss/base';

@import '../node_modules/tailwindcss/components';

@import '../node_modules/tailwindcss/utilities';

//! utilities
// global mixins
// @import './styles/utils/mixins';
// global variables
// @import './styles/utils/variables';

//! setup
// normalize browsers to make more consistent
// @import './styles/setup/normalize';
// clearfix for floats
// @import './styles/setup/clearfix';
// global border-box setting
// @import './styles/setup/border-box';

//! global styles
// global typography
// @import './styles/global/typography';
// global styles
// @import './styles/global/global';

//! sections
// @import './styles/layout/header';

body * {
  font-family: 'Varela Round', sans-serif;
}

:focus,
:active {
  outline: none !important;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(
    to right,
    #fdf9f9,
    hsl(0, 5%, 68%),
    #fdf9f9
  );
}

nav {
  height: 60px;
}

.logo {
  img {
    height: 24px;
  }
}

.heading {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
}

#hero-title {
  @media only screen and (min-width: 768px) and (max-width: 1000px) {
    min-height: 60px;
    max-width: 50%;
    margin: auto;
  }

  @media (max-width: 425px) {
    min-height: 60px;
    min-width: 300px;
  }
}

.description-title {
  @media only screen and (min-width: 425px) and (max-width: 769px) {
    min-height: 50px;
    max-width: 80%;
    margin: auto;
  }
  @media (max-width: 425px) {
    min-height: 75px;
    // max-width: 74%;
    // margin: auto;
  }
}

.description-content {
  @media only screen and (min-width: 425px) and (max-width: 769px) {
    min-height: 54px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 425px) {
    min-height: 81px;
  }
}

.feature {
  img {
    height: 60px;
    width: 60px;
  }
}
.preview-section {
  position: relative;
  min-height: 555px;
  // background: #9b59b6;
  // * {
  //   color: white;
  // }
  #description-image-container {
    // position: absolute;
    // width: 95%;
    // width: 60%;
    height: 100%;
    right: 0px;
    top: 60px;
    img {
      // width: 850px;
      width: 100%;
      height: 100%;
      // margin: auto;
    }
  }
  @media (min-width: 1280px) {
    .paginator {
      // position: absolute;
      // left: 5.8rem;
      // bottom: 1%;
      button {
        width: 30px;
        @apply px-1;
      }
    }
  }

  @media (min-width: 1024px) {
    #description {
      height: 17rem;
    }
  }
}

// pricing cards
@media only screen and (max-width: 768px) {
  .plan {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.feather {
  width: 20px;
  height: 34px;
  // stroke: hsl(218, 17%, 55%);
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

#hero-title-container {
  overflow: hidden;
}

#previous-button {
  outline-color: transparent;
}

#next-button {
  outline-color: transparent;
}

button:disabled {
  cursor: default;
}

// title animation

.animated-title {
  animation-name: slideIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.removing-title {
  animation-name: slideOut;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-30px);
  }
}

#hero-title {
  span {
    font-family: 'IBM Plex Sans', sans-serif;
  }
}

#color-1 {
  // background-color: #9b59b6;
  background-color: hsl(293, 33%, 95%);
  * {
    // color: white !important;
    color: hsla(330, 90%, 24%, 1) !important;
  }
}

#color-2 {
  // background-color: #3ecc98;
  background-color: hsla(101, 53%, 94%, 1);
  * {
    // color: white;
    color: hsl(118, 42%, 30%);
  }
}

#color-3 {
  // background-color: #f73656;
  background-color: hsl(19, 81%, 92%);
  * {
    // color: white;
    color: hsl(2, 68%, 39%);
  }
}

#color-4 {
  // background-color: #ff7c0a;
  background-color: hsl(6, 78%, 85%);
  * {
    // color: white !important;
    color: hsl(6, 78%, 31%) !important;
  }
}

#hero-title[title='color-1'] {
  color: hsla(330, 90%, 24%, 1) !important;
}

#hero-title[title='color-2'] {
  color: hsl(118, 42%, 30%);
}

#hero-title[title='color-3'] {
  color: hsl(2, 68%, 39%);
}

#hero-title[title='color-4'] {
  color: hsl(6, 78%, 31%) !important;
}


.Typewriter__wrapper {
  display: inline !important;
}

.Typewriter__cursor {
  position: sticky;
  right: 0;
}

.swal-footer {
  text-align: center;
  button {
    &:hover {
      background: #2b6cb0 !important;
    }
  }
}

.swal2-file::placeholder,
.swal2-input::placeholder,
.swal2-textarea::placeholder {
  color: gray !important;
}

.success-title-swal {
  color: #38a169 !important;
}
